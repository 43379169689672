import React from "react"

const Footer = () => (
  <footer>
    <div className="social-media-container flex-center">
      <a
        className="social-media-button"
        href="https://blog.pawelzdralewicz.pl/"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fal fa-books"></i>
      </a>
      <a
        className="social-media-button"
        href="https://www.linkedin.com/in/pzdralewicz/"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-linkedin-in"></i>
      </a>
      <span className="social-media-text">Paweł Zdralewicz {new Date().getFullYear()}</span>
      <a
        className="social-media-button"
        href="https://quote.pawelzdralewicz.pl/"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fal fa-quote-right"></i>
      </a>
      <a
        className="social-media-button"
        href="https://github.com/pzdralewicz/"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-github"></i>
      </a>
    </div>
  </footer>
)

export default Footer

import React from 'react';
import Image from "./image"

const Logo = () => (
  <div id="logo-container">
    {Image().logo}
  </div>
);

export default Logo;
